import React from 'react';
import Icon, { IconProps } from './Icon';

const Square: React.FC<IconProps> = ({ width = '16px', height = '16px', className, onClick }) => {
  return (
    <Icon onClick={onClick} width={width} height={height} className={className} viewBox="0 0 448 512">
      <path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-6 400H54c-3.3 0-6-2.7-6-6V86c0-3.3 2.7-6 6-6h340c3.3 0 6 2.7 6 6v340c0 3.3-2.7 6-6 6z" />
    </Icon>
  );
};

export default Square;
