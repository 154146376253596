import React from 'react';
import Icon, { IconProps } from './Icon';

type TaskcafeProps = {
  innerColor?: string;
  outerColor?: string;
} & IconProps;

const Taskcafe: React.FC<TaskcafeProps> = ({
  innerColor = '#262c49',
  outerColor = '#7367f0',
  width = '16px',
  height = '16px',
  className,
}) => {
  return (
    <Icon width={width} height={height} className={className} viewBox="0 0 800 800">
      <path
        d="M371.147 371.04c-59.092 0-106.995 47.903-106.995 106.995 0 59.092 47.903 106.995 106.995 106.995 59.092 0 106.995-47.903 106.995-106.995 0-59.092-47.903-106.996-106.995-106.996zm0 20.708c47.687 0 86.287 38.592 86.287 86.287 0 47.687-38.592 86.286-86.287 86.286-47.687 0-86.286-38.592-86.286-86.286 0-47.688 38.592-86.287 86.286-86.287m60.489 56.201l-9.723-9.8a5.177 5.177 0 00-7.321-.03l-60.984 60.494-25.796-26.006a5.177 5.177 0 00-7.322-.03l-9.802 9.723a5.177 5.177 0 00-.029 7.322l39.166 39.483a5.177 5.177 0 007.321.03l74.46-73.864a5.178 5.178 0 00.03-7.321z"
        fill={outerColor}
      />
      <path
        d="M264.69 682.877H467.7c56.038 0 101.504-45.465 101.504-101.504h33.835c74.648 0 135.34-60.692 135.34-135.34s-60.692-135.34-135.34-135.34H188.562a25.315 25.315 0 00-25.376 25.377v245.303c0 56.039 45.465 101.504 101.504 101.504zM603.04 378.364c37.324 0 67.67 30.345 67.67 67.67 0 37.323-30.346 67.669-67.67 67.669h-33.835v-135.34zm50.435 406.018H112.75c-50.329 0-64.497-67.67-38.064-67.67h616.746c26.434 0 12.477 67.67-37.958 67.67z"
        fill={outerColor}
      />
      <g fill="none" stroke={outerColor} strokeWidth="3.392">
        <path
          d="M286.302 276.2c-12.491-12.65-24.51-24.821-23.906-35.239.604-10.417 14.068-18.838 26.772-30.358 12.705-11.52 24.65-26.139 14.907-39.935-9.743-13.797-41.175-26.772-40.697-42.89.478-16.119 32.868-35.378 37.236-52.814 4.369-17.435-19.286-33.048-42.944-48.662M374.624 276.2c-12.492-12.65-24.51-24.821-23.907-35.239.604-10.417 14.068-18.838 26.773-30.358 12.704-11.52 24.65-26.139 14.906-39.935-9.742-13.797-41.174-26.772-40.696-42.89.478-16.119 32.867-35.378 37.236-52.814 4.368-17.435-19.287-33.048-42.944-48.662M462.945 276.2c-12.491-12.65-24.51-24.821-23.906-35.239.604-10.417 14.068-18.838 26.772-30.358 12.705-11.52 24.65-26.139 14.907-39.935-9.743-13.797-41.175-26.772-40.697-42.89.478-16.119 32.868-35.378 37.236-52.814 4.369-17.436-19.286-33.048-42.944-48.662"
          strokeWidth="28.00374144"
        />
      </g>
      <path
        d="M371.147 363.194c-73.749 0-133.534 59.785-133.534 133.534 0 73.75 59.785 133.535 133.534 133.535 73.75 0 133.535-59.786 133.535-133.535s-59.786-133.534-133.535-133.534zm0 25.845c59.516 0 107.69 48.165 107.69 107.69 0 59.515-48.165 107.688-107.69 107.688-59.515 0-107.689-48.164-107.689-107.689 0-59.515 48.165-107.689 107.69-107.689m75.492 70.142l-12.135-12.232a6.462 6.462 0 00-9.138-.039l-76.11 75.498-32.194-32.456a6.463 6.463 0 00-9.138-.038l-12.233 12.134a6.451 6.451 0 00-.025 9.138l48.88 49.277a6.462 6.462 0 009.138.038l92.93-92.183a6.452 6.452 0 00.024-9.138z"
        fill={innerColor}
      />
    </Icon>
  );
};

export default Taskcafe;
