import React from 'react';
import Icon, { IconProps } from './Icon';

const CheckSquare: React.FC<IconProps> = ({ width = '16px', height = '16px', onClick, className }) => {
  return (
    <Icon width={width} onClick={onClick} height={height} className={className} viewBox="0 0 448 512">
      <path d="M400 480H48c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48h352c26.51 0 48 21.49 48 48v352c0 26.51-21.49 48-48 48zm-204.686-98.059l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.248-16.379-6.249-22.628 0L184 302.745l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.25 16.379 6.25 22.628.001z" />
    </Icon>
  );
};

export default CheckSquare;
